<template>
  <div>
    <v-autocomplete
      :value="selectedDateType"
      :items="dateTypes"
      @input="handleSelectedDateTypeChange"
      item-text="label"
      item-value="id"
      v-bind="$attrs"
    ></v-autocomplete>
  </div>
</template>

<script>
import { indexDateTypes as index } from "@/services/configs/leads/status_automations";

export default {
  name: "SelectDateTypes",

  data() {
    return {
      dateTypes: [],
    };
  },
  props: {
    selectedDateType: Number,
  },
  methods: {
    handleSelectedDateTypeChange(newDateType) {
      this.$emit("change", newDateType);
    },
    async loadDateTypes() {
      const response = await index();
      this.dateTypes = response.data;
    },
  },
  created() {
    this.loadDateTypes();
  },
  model: {
    prop: "selectedDateTypes",
    event: "change",
  },
};
</script>
