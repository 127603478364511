<template>
  <div>
    <v-autocomplete
      :value="selectedStatus"
      :items="leadStatuses"
      @input="handleSelectedStatusChange"
      item-text="name"
      item-value="id"
      v-bind="$attrs"
    ></v-autocomplete>
  </div>
</template>

<script>
import { getLeadStatuses } from "@/services/lead.js";

export default {
  name: "LeadStatusAutoComplete",

  data() {
    return {
      leadStatuses: [],
    };
  },
  props: {
    selectedStatus: Number,
  },
  methods: {
    handleSelectedStatusChange(newStatus) {
      this.$emit("change", newStatus);
    },
  },
  created() {
    getLeadStatuses().then((statuses) => {
      this.leadStatuses = statuses;
    });
  },
  model: {
    prop: "selectedStatus",
    event: "change",
  },
};
</script>
