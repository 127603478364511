<template>
  <ConfigPage>
    <ConfigItem
      :title="__('Automatização de status')"
      :description="
        __('Define as regras para automatizar a mudança automática de status')
      "
    >
      <v-card outlined class="mb-12">
        <v-list>
          <div v-for="item in items" :key="item.id">
            <v-list-item @click="openFormFilled(item)">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>
                    {{ item.start_status_description }}
                    <v-icon small>mdi-arrow-right</v-icon>
                    {{ item.end_status_description }}
                  </strong>
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap white-space-normal">
                  <div v-html="formatShortDescription(item)"></div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bin="attrs" icon small>
                        <v-icon small>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <span v-html="formatDescription(item)"></span>
                  </v-tooltip>
                  <v-btn
                    @click.stop="handleDelete(item)"
                    icon
                    small
                    color="red"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <v-card-actions>
          <v-btn @click="openForm()" text color="primary" small class="mt-2">
            <v-icon left>mdi-plus</v-icon> {{ __("Nova regra") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ConfigItem>
    <StatusAutomationForm
      ref="form"
      @submit="handleSubmit"
      :title="__('Automatização de status')"
      :dateTypes="date_types"
    />
  </ConfigPage>
</template>

<script>
import i18n from "@/mixins/i18n";
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import StatusAutomationForm from "@/components/StatusAutomations/StatusAutomationForm.vue";
import {
  destroyStatusAutomation as destroy,
  indexStatusAutomation as index,
  storeStatusAutomation as store,
  updateStatusAutomation as update,
} from "@/services/configs/leads/status_automations";

export default {
  name: "StatusAutomation",
  mixins: [i18n],
  components: { ConfigPage, ConfigItem, StatusAutomationForm },
  data() {
    return {
      i18n_prefix: "settings",
      items: [],
      form: {},
      fetching: false,
      submitting: false,
      date_types: [],
      loading: false,
      dayText: "",
    };
  },

  methods: {
    async loadItems() {
      this.loading = true;

      try {
        const response = await index();
        this.items = response.data;
        this.defineTextDay(this.items.days);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    openFormFilled(item) {
      this.$refs.form.init(item);
    },
    openForm() {
      this.$refs.form.init();
    },
    async handleDelete(item) {
      if (
        !confirm(
          `Tem certeza que deseja excluir a ação que automatiza status para ${item.date_type_description}?`
        )
      ) {
        return;
      }

      await destroy(item.id);
      this.items = this.items.filter((i) => i.id !== item.id);
    },

    async handleSubmit(form) {
      this.$refs.form.loading = true;
      this.$refs.form.setErrors();

      try {
        let newItem;
        if (form.id) {
          newItem = await update(form.id, form);
        } else {
          newItem = await store(form);
        }
        this.$refs.form.close();

        this.updateOrAddItem(newItem);
        this.loadItems();
      } catch (error) {
        console.error(error);
        if (error.response?.data?.errors) {
          this.$refs.form.setErrors(error.response.data.errors);
        }
      } finally {
        this.$refs.form.loading = false;
      }
    },
    updateOrAddItem(newItem) {
      if (this.items.find((item) => item.id === newItem.id)) {
        this.items = this.items.map((item) => {
          if (item.id === newItem.id) {
            return newItem;
          }

          return item;
        });

        return;
      }

      this.items.push(newItem);
    },
    getDaysText(days) {
      return days === 0
        ? "No mesmo dia da"
        : `Após ${days} dia${days > 1 ? "s" : ""} da`;
    },
    formatShortDescription(item) {
      return `${this.getDaysText(item.days)} <strong>${
        item.date_type_description
      }</strong>.`;
    },
    formatDescription(item) {
      return `
      Todos os processos que estiverem no status <strong>${
        item.start_status_description
      }</strong> 
      passarão para <strong>${item.end_status_description}</strong>
      ${this.getDaysText(item.days)} <strong>${
        item.date_type_description
      }</strong>.
    `;
    },
  },

  created() {
    this.loadItems();
  },
};
</script>
