import Vue from "vue";

export async function indexStatusAutomation() {
  const response = await Vue.prototype.$http.get(
    "/status_automation/date_rules"
  );

  return response.data;
}

export async function indexDateTypes() {
  const response = await Vue.prototype.$http.get(
    "/status_automation/date_types"
  );

  return response.data;
}

export async function destroyStatusAutomation(id) {
  await Vue.prototype.$http.delete(`/status_automation/date_rules/${id}`);
}

export async function storeStatusAutomation(payload) {
  const response = await Vue.prototype.$http.post(
    "/status_automation/date_rules",
    payload
  );

  return response.data;
}

export async function updateStatusAutomation(id, payload) {
  const response = await Vue.prototype.$http.put(
    `/status_automation/date_rules/${id}`,
    payload
  );

  return response.data;
}
