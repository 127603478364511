<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card :disabled="loading">
      <v-form @submit.prevent="submit">
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <SelectDateTypes
            :label="__('Data')"
            :selectedDateType="form.date_type_id"
            @change="handleDateTypeChange"
            outlined
          />
          <v-text-field
            v-model="form.days"
            :error-messages="errors.days"
            :label="__('Dias')"
            type="number"
            outlined
          ></v-text-field>
          <LeadStatusAutoComplete
            :label="__('Status inicial')"
            :selectedStatus="form.start_status_id"
            @change="handleStartStatusChange"
            outlined
          />
          <LeadStatusAutoComplete
            :label="__('Status final')"
            :selectedStatus="form.end_status_id"
            @change="handleEndStatusChange"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close()" text> {{ __("Cancelar") }} </v-btn>
          <v-btn type="submit" color="primary" :loading="loading">
            {{ __("Confirmar") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/mixins/i18n";
import LeadStatusAutoComplete from "@/components/LeadStatuses/LeadStatusAutoComplete.vue";
import SelectDateTypes from "@/components/StatusAutomations/SelectDateTypes.vue";
export default {
  name: "StatusAutomationForm",
  mixins: [i18n],
  props: {
    title: String,
    dateTypes: Array,
  },
  components: { LeadStatusAutoComplete, SelectDateTypes },
  data() {
    return {
      i18n_prefix: "settings",
      loading: false,
      dialog: false,
      errors: {},
      form: {
        id: null,
        date_type_id: null,
        date_type_description: null,
        start_status_id: null,
        start_status_description: null,
        end_status_id: null,
        end_status_description: null,
      },
    };
  },

  methods: {
    reset(initial = null) {
      if (!initial) {
        this.form = {
          id: null,
          date_type_id: null,
          date_type_description: null,
          start_status_id: null,
          start_status_description: null,
          end_status_id: null,
          end_status_description: null,
        };
      } else {
        this.form = {
          ...initial,
        };
      }

      this.setErrors();
    },

    init(initial = null) {
      this.reset(initial);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },

    submit() {
      this.$emit("submit", { ...this.form });
    },

    handleStartStatusChange(newStatus) {
      this.form.start_status_id = newStatus;
    },

    handleEndStatusChange(newStatus) {
      this.form.end_status_id = newStatus;
    },

    handleDateTypeChange(newDateType) {
      this.form.date_type_id = newDateType;
    },
  },
};
</script>
